import { type } from './shared';

export const deserialize = ({ editor, position, data, ...node }) => {
  return {
    ...node,
    type,
    children: [{ text: '' }],
    align:
      data?.hProperties?.align ||
      (data?.hProperties?.className === 'border' || data?.hProperties?.width ? 'center' : ''),
    width: data?.hProperties?.width,
    border: data?.hProperties?.className === 'border' || node.border,
    url: node.url || node.src,
  };
};

export const serialize = ({ children, align, border = false, isInline, width, url, ...image }) => {
  if (isInline) {
    return {
      src: url,
      url,
      ...image,
    };
  }

  image.data = {
    hProperties: {
      ...(align && { align }),
      ...(border && { className: 'border' }),
      ...(width && { width }),
    },
  };

  return {
    align,
    width,
    src: url,
    url,
    ...image,
  };
};
