import type { RenderElementProps } from 'slate-react';

import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Box from '@ui/Box';
import type { DropdownRef } from '@ui/Dropdown';
import EmbedMenu, { useEmbedMenu } from '@ui/MarkdownEditor/editor/EmbedMenu';
import SelectionWrapper from '@ui/MarkdownEditor/editor/SelectionWrapper';
import { EmbedTypes } from '@ui/MarkdownEditor/enums';
import type { EmbedBlock } from '@ui/MarkdownEditor/types';

import EmbedToolbar from '../../EmbedMenu/Toolbar';
import EmptyBlock from '../EmptyBlock';

import classes from './style.module.scss';
import YouTube from './YouTube.png';

interface EmbedProps extends RenderElementProps {
  element: EmbedBlock;
}

const Embed = ({ attributes, children, element }: EmbedProps) => {
  const editor = useSlateStatic();
  const { typeOfEmbed = EmbedTypes.default, url, height, width, openMenu, ...props } = element;
  const [, { open, close }] = useEmbedMenu();
  const menuRef = useRef<DropdownRef>(null);

  const openEmbedMenu = useCallback(
    event => {
      // If we're dragging selection across the emebed in any way, don't open the menu
      // @note: I'd rather use acrossBlocks here, but technically if we've only selected
      // the line just above the image, we're not across blocks when we cursor into the image
      if (editor.selection && !isEqual(editor.selection.anchor, editor.selection.focus)) return;

      event.preventDefault();
      open(element, element.typeOfEmbed ?? EmbedTypes.default);
    },
    [editor, open, element],
  );

  const closeEmbedToolbar = useCallback(
    event => {
      event.preventDefault();
      const embedEl = ReactEditor.toDOMNode(editor, element);
      const embedRect = embedEl.getBoundingClientRect();
      const isOutsideEmbed =
        event.clientX < embedRect.left ||
        event.clientX > embedRect.right ||
        event.clientY < embedRect.top ||
        event.clientY > embedRect.bottom;
      if (isOutsideEmbed) close();
    },
    [editor, close, element],
  );

  const iframe = useMemo(() => {
    if (!element.html) return null;

    return typeof DOMParser !== 'undefined'
      ? (new DOMParser().parseFromString(element.html, 'text/html').body.children[0] as HTMLIFrameElement)
      : null;
  }, [element.html]);

  useEffect(() => {
    if (!openMenu || !menuRef?.current?.toggle) return;

    menuRef.current.toggle();
    Transforms.setNodes(editor, { openMenu: false }, { at: ReactEditor.findPath(editor, element) });
  }, [editor, element, element.openMenu, openMenu]);

  // @note: The google docs pdf view sometimes returns a 204. Which results in
  // the iframe being blank. A simple retry is usually enough to get it to
  // return _something_. This mechanism allows for more resilient retries
  // though.
  //
  // adapted from here: https://gist.github.com/rpggio/5f521e1f15f969529f4fe506fc0d1c0a
  useEffect(() => {
    if (!url || typeOfEmbed !== 'pdf') return;

    const duration = 100;
    let retries = 0;
    let timeoutId: NodeJS.Timeout | null;

    const el = ReactEditor.toDOMNode(editor, element);
    const iframeRef = el.querySelector('iframe');
    if (!iframeRef) return;

    const cleanup = () => {
      if (timeoutId) clearTimeout(timeoutId);
      iframeRef?.removeEventListener('load', cleanup);
    };

    iframeRef.addEventListener('load', cleanup);

    const retryer = () => {
      if (retries >= 10) return;
      retries += 1;

      iframeRef.src = iframe?.src || url;
      timeoutId = setTimeout(retryer, duration * 2 ** retries);
    };

    retryer();

    // eslint-disable-next-line consistent-return
    return cleanup;
  }, [editor, element, iframe?.src, typeOfEmbed, url]);

  if (url) {
    return (
      <SelectionWrapper
        blockType={typeOfEmbed}
        contentEditable={false}
        element={element}
        onMouseEnter={openEmbedMenu}
        onMouseLeave={closeEmbedToolbar}
        {...attributes}
      >
        <EmbedToolbar element={element} menuRef={menuRef}>
          {typeOfEmbed === 'github' ? (
            <div
              dangerouslySetInnerHTML={{ __html: element.html || '' }}
              data-testid="editor-embed-for-test"
              title={iframe?.title}
            ></div>
          ) : (
            <iframe
              allow={iframe?.allow}
              allowFullScreen={iframe?.allowFullscreen}
              className={iframe?.className}
              data-testid="editor-embed-for-test"
              frameBorder={iframe?.frameBorder}
              height={height || iframe?.height || '300px'}
              scrolling={iframe?.scrolling}
              src={iframe?.src || url}
              title={iframe?.title}
              width={width || iframe?.width || '100%'}
            />
          )}
        </EmbedToolbar>
        {children}
      </SelectionWrapper>
    );
  }

  const icon =
    typeOfEmbed === 'github'
      ? 'icon-github'
      : typeOfEmbed === 'pdf'
        ? 'icon-file'
        : typeOfEmbed === 'jsfiddle'
          ? 'icon-jsfiddle'
          : 'icon-maximize';
  const text =
    typeOfEmbed === 'github'
      ? 'Embed GitHub Gist'
      : typeOfEmbed === 'pdf'
        ? 'Embed PDF'
        : typeOfEmbed === 'jsfiddle'
          ? 'Embed JSFiddle'
          : typeOfEmbed === 'iframe'
            ? 'Embed Iframe'
            : 'Embed URL';

  return (
    <SelectionWrapper contentEditable={false} element={element} {...attributes}>
      {typeOfEmbed === 'youtube' ? (
        <EmbedToolbar element={element} menuRef={menuRef}>
          <Box
            className={classes['Embed-Empty-Youtube']}
            contentEditable={false}
            data-testid="embed-menu-button"
            kind="rule"
            onClick={openEmbedMenu}
            {...props}
          >
            <img alt="YouTube icon" className={classes['Embed-Empty-Youtube_icon']} src={YouTube} />
            {children}
          </Box>
        </EmbedToolbar>
      ) : (
        <>
          <EmptyBlock icon={icon} testId="embed-menu-button" text={text}>
            <EmbedMenu element={element} menuRef={menuRef} />
          </EmptyBlock>
          {children}
        </>
      )}
    </SelectionWrapper>
  );
};

export default Embed;
