/* eslint-disable consistent-return */
import { Editor, Node, Text, Transforms } from 'slate';

import type { Normalizer } from '@ui/MarkdownEditor/types';

import { isParagraph } from '../Paragraph/shared';

import { insertJsxFlow } from './operations';
import { isJsxFlowElement, startingTagRegex } from './shared';

const convertToJsxFlowElement: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!editor.props.useMDX) return next();
    if (!Text.isText(node)) return next();

    const match = node.text.match(startingTagRegex);
    if (!match || typeof match.index === 'undefined') return next();

    const value = node.text.slice(match.index);
    const at = {
      anchor: { path, offset: match.index },
      focus: { path, offset: node.text.length },
    };

    insertJsxFlow(editor, value, { at });
  };

const convertFromEsm: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!editor.props.useMDX) return next();
    if (!(isParagraph(node) && path.length === 1)) return next();

    const string = Node.string(node);
    if (!string.match(/^export /)) return next();

    insertJsxFlow(editor, string, { at: Editor.range(editor, path) });
  };

const unwrapJsxFlowElement: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (!editor.props.useMDX) return next();
    if (!isJsxFlowElement(node)) return next();

    const parent = Editor.parent(editor, path);
    if (!parent || Editor.isEditor(parent[0])) return next();

    Transforms.unwrapNodes(editor, { at: path, match: (_, p) => p.length === parent[1].length, split: true });
  };

export default [convertToJsxFlowElement, convertFromEsm, unwrapJsxFlowElement];
